import React from 'react';
import logoImage from '../images/logo.png';  // Adjust the path based on your project structure
import Typography from '@mui/material/Typography';

const Logo = () => {
  const flexContainer = {
    display: 'flex',         // Make the container flex container
    alignItems: 'center',    // Align items vertically in the center
  };

  const logoStyle = {
    width: '40px',           // Set width for the logo image
    marginRight: '10px',     // Add some spacing between image and text
  };

  return (
    <div className="logo" style={flexContainer}>
      <img src={logoImage} alt="Logo" style={logoStyle} />
      <Typography variant="h5" color={'#000'} marginRight={5}>eleyy</Typography>
    </div>
  );
};

export default Logo;
