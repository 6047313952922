import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';
import { Padding, VerticalAlignCenter } from '@mui/icons-material';

const whiteLogos = [
  'https://injaz.org.jo/_nuxt/img/6534457568.d44f53c.png',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d520d0517ae8e8ddf13_Bern-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f46794c159024c1af6d44_Montreal-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e891fa22f89efd7477a_TerraLight.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a09d1f6337b1dfed14ab_colorado-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5caa77bf7d69fb78792e_Ankara-white.svg',
];

const darkLogos = [
  'https://injaz.org.jo/_nuxt/img/6534457568.d44f53c.png',
  'https://www.umniah.com/storage/thetanklogo-1.png',
  'https://www.ipark.jo/wp-content/uploads/2020/12/iPARK-New-Logo.png',
  'https://www.orangecorners.com/app/themes/orange-corners/dist/images/oc_logo_aef04c1c.svg',
  'https://images.squarespace-cdn.com/content/v1/6075e59d837e85168eab19d2/fa082874-fe89-439e-91e6-d26efe641c7e/Watermark%2Blogo+%281%29.png?format=1500w',
  'https://i2.wp.com/www.qrce.org/wp-content/uploads/2020/04/QRCE-Retina-Logo.png?fit=676%2C200&ssl=1',
  // 'https://orange.jo/themes/custom/orange/logo.svg',
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSR8IEXWC2ytgxr9MQGJq8H35-9QqB_7nTkZA&s'
];

const logoStyle = {
  width: '140px',
  height: '100%',
  margin: '0 20px',
  opacity: 1,
  filter: 'grayscale(100%)',
  padding: '0px'
};

export default function LogoCollection() {
  const theme = useTheme();
  const logos = theme.palette.mode === 'light' ? darkLogos : whiteLogos;

  return (
    <Box id="logoCollection" sx={{ py: 10 }}>
      <Typography
        component="p"
        variant="h5"
        align="center"
        sx={{ color: 'text.secondary' }}
      >
        Trusted by the best incubators and accelerators
      </Typography>
      <Grid container sx={{ justifyContent: 'center', alignItems: 'center', mt: 5, opacity: 0.7 }}>
        {logos.map((logo, index) => (
          <Grid item key={index}>
            <img
              src={logo}
              alt={`company number ${index + 1}`}
              style={logoStyle}
            />
          </Grid>
        ))}
      </Grid>

    </Box >
  );
}
