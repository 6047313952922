import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import mockups from '../../src/images/mockups.png';
import { visuallyHidden } from '@mui/utils';
import { styled } from '@mui/material/styles';
import axios from 'axios';

const StyledBox = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  width: '100%',
  height: 400,
  backgroundImage: `url(${mockups})`,
  backgroundRepeat: 'no-repeat',
  outlineColor: 'hsla(220, 25%, 80%, 0.5)',
  backgroundSize: 'contain',
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(1),
    height: 700,
  },
}));

export default function Hero() {
  const [email, setEmail] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const response = await axios.post('https://backend.eleyy.com/api/subscribe', {
        name: 'no name',
        email,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status >= 200 && response.status < 300) {
        setEmail('');
        alert('Thank you for subscribing!');
      } else {
        throw new Error('Failed to subscribe');
      }
    } catch (error) {
      console.error('Error details:', error);
      const errorMessage = error.response?.data?.error || error.message || 'An error occurred. Please try again.';
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage:
          'radial-gradient(ellipse 100% 70% at 50% 10%, #93268f, #ee2a7b)',
        ...theme.applyStyles('dark', {
          backgroundImage:
            'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
        }),
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 0, sm: 0 },
        }}
      >
        <Stack
          spacing={4}
          useFlexGap
          sx={{ alignItems: 'center', width: { xs: '100%', sm: '100%' } }}
        >
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              textAlign: 'center',
              alignItems: 'center',
              fontSize: 'clamp(3rem, 10vw, 3.5rem)',
              color: '#fff',
              fontWeight: '700'
            }}
          >
            Discover the perfect gift with eleyy
          </Typography>
          <Typography variant='h6'
            sx={{
              textAlign: 'center',
              color: '#fff',
              width: { sm: '100%', md: '80%' },
              fontWeight: '600'
            }}
          >
            eleyy is an innovative platform that uses artificial intelligence to analyze your
            loved ones preferences and provide personalized gift recommendations.
            With eleyy, finding the perfect gift has never been easier.
          </Typography>
          <Typography variant="h6"
            sx={{
              textAlign: 'center',
              color: '#fff',
              width: { sm: '100%', md: '80%' },
            }}
          >
            We are not yet launch! please submit your email address to join the waiting list.
          </Typography>
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={1}
              useFlexGap
              sx={{ pt: 2, width: { xs: '100%', sm: 'auto', justifyContent: 'center' } }}
            >
              <InputLabel htmlFor="email-hero" sx={visuallyHidden}>
                Email
              </InputLabel>
              <TextField
                id="email-hero"
                hiddenLabel
                size="small"
                variant="outlined"
                aria-label="Enter your email address"
                placeholder="Your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                slotProps={{
                  htmlInput: {
                    autoComplete: 'on',
                    'aria-label': 'Enter your email address',
                  },
                }}
              />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? 'Joining...' : 'Join!'}
              </Button>
            </Stack>
          </form>

          {error && (
            <Typography variant="caption" color="white">
              {typeof error === 'string' ? error : JSON.stringify(error)}
            </Typography>
          )}

          <Typography variant="caption" color="white">
            By clicking "JOIN" you agree to our&nbsp;
            <Link href="#" color="white">
              Terms & Conditions
            </Link>
          </Typography>
        </Stack>
        <StyledBox id="image" />
      </Container>
    </Box>
  );
}
